.intro {
    padding-top: $baseline * 4;

    &__text {
        width: 635px;
    }

    &__iphone {
        width: 133px;
        will-change: transform;
        transform: translateY(100px);

        &_verbal {
            height: 436px;
            transition: transform 2.5s ease-in-out;
        }

        &_ipsative {
            height: 444px;
            transition: transform 2.25s ease-in-out;
        }

        &_numerical {
            height: 452px;
            transition: transform 1.75s ease-in-out;
        }
    }

    &__iphones-wrapper {
        position: relative;

        &.animate .intro__iphone {
            transform: translateY(0);
        }

        .intro__iphone {
            position: absolute;
            left: 50%;

            &_verbal {
                top: 00px;
                margin-left: -120px;
            }

            &_ipsative {
                top: 183px;
                margin-left: -67px;
            }

            &_numerical {
                top: 103px;
                margin-left: 0px;
            }
        }
    }

    $ipad-width: 750px;
    $ipad-height: 574px;
    $ipad-shadow-width: 880px;
    $ipad-shadow-height: 695px;

    &__ipad {
        position: relative;

        width: $ipad-width;
        height: $ipad-height;

        &:after {
            top: 0;
            left: 0;
            z-index: -1;
            position: absolute;

            width: $ipad-shadow-width;
            height: $ipad-shadow-height;
            content: '';

            @include background-image('../../images/intro/ipad-shadow.png');
            @include background-image-2x('../../images/intro/ipad-shadow@2x.png');
        }

        &_vertical {
            width: $ipad-height;
            height: $ipad-width;

            &:after {
                bottom: 100%;

                transform-origin: top left;
                transform: rotate(-90deg) scaleX(-1);
            }
        }
    }

    &__ipads-wrapper {
        position: relative;
        overflow: hidden;

        height: 1425px;
        padding-bottom: 100px;
        margin-bottom: -100px;

        .row {
            @include flex-wrap(nowrap);
            @include justify-content(center);

            &__column {
                @include flex(1 1 0);

                margin: $baseline;
                min-width: $ipad-width;

                &:first-child {
                    @include flexbox();
                    @include justify-content(flex-end);
                }
            }
        }
    }

    &__ipads {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}
