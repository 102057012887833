.questionnaires {
    overflow: hidden;

    &__text p {
        width: 680px;
    }

    &__surface-studio-wrapper {
        position: relative;

        &.animate .questionnaires__surface-studio {
            transform: translate(0, 0);
        }
    }

    &__surface-studio {
        position: absolute;
        top: 0;
        left: 0;

        width: 640px;
        height: 641px;
        will-change: transform;
        transform: translate(30px, 26px);
        transition: transform 5s ease-in-out;
    }

    &__surface-book {
        position: absolute;
        left: 0;
        display: block;

        width: 841px;
        height: 546px;

        &:after {
            position: absolute;
            top: -1px;
            left: -22px;
            z-index: -1;

            width: 975px;
            height: 644px;
            content: '';

            @include background-image('../../images/questionnaires/surface-book-shadow.png');
            @include background-image-2x('../../images/questionnaires/surface-book-shadow@2x.png');
        }

        &_sales {
            width: 827px;
            height: 641px;
            z-index: 2;

            &:after {
                top: auto;
                left: auto;
                right: 58px;
                bottom: -124px;
                z-index: -1;

                width: 1175px;
                height: 410px;

                @include background-image('../../images/questionnaires/surface-book-angled-shadow.png');
                @include background-image-2x('../../images/questionnaires/surface-book-angled-shadow@2x.png');
            }
        }
    }

    &__surface-books-wrapper {
        position: relative;

        height: 1017px;
        margin-top: $baseline * 2;

        .questionnaires__surface-book {
            position: absolute;
            top: 0;
            left: -70px;

            &_competencies {
                top: 472px;
            }

            &_sales {
                top: 50px;
                right: -110px;
                left: auto;
            }
        }
    }
}
