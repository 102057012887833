.announcement {
    @include flexbox();
    @include justify-content(center);

    color: #fff;
    margin: $baseline 0 32px;
    background-color: $brand-primary;

    &__text {
        padding: 0.4rem;

        // &:before,
        // &:after {
        //     margin: 0 0.5rem;
        //     content: "•";
        //     color: #ffff00;
        // }

        a {
            color: #fff;
        }
    }
}
