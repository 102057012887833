// Global
$body-min-width: 1280px;

// Grid
$grid-columns: 12;
$grid-width: 1214px;

// Colors
$brand-primary: #46716d;
$brand-primary-text: #324c4a;
$text-color-link: #0563c1;
$text-color-main: #000;

// Typography
$font-size-base: 18pt;
$line-height-base: 1.4;
$baseline: $font-size-base * $line-height-base;
