.motivation {
    overflow: hidden;

    padding-top: $baseline * 7;

    &__ipad {
        will-change: transform;

        &_dd-all {
            width: 419px;
            height: 493px;
            transform: translateY(-20px);
            transition: transform 2s ease-out;
        }

        &_dd-recommendations {
            width: 508px;
            height: 599px;
            transform: translateY(20px);
            transition: transform 2s ease-out;
        }
    }

    &__ipads-section {
        padding-top: 4rem;
    }

    &__ipads-wrapper {
        position: relative;
        height: 653px;

        &.animate .motivation__ipad {
            transform: translateY(0);
        }

        .motivation__ipad {
            top: 0;
            left: 0;
            position: absolute;

            &_dd-all {
                top: 160px;
                left: 50px;
                z-index: 2;
            }

            &_dd-recommendations {
                z-index: 1;
                left: 240px;
                top: -80px;
            }
        }
    }
}
