.clients {

    &__logo {
        height: 50px;
        margin: 0 1rem;

        &_detech {
            width: 180px;
            height: 54px;

            margin: 0;
            margin-top: $baseline * 4;
        }

        &_aeroflot {
            width: 182px;
        }

        &_sanofi,
        &_sanofi-ru {
            height: 30px;
            width: 140px;
        }

        &_sanofi {
            margin-top: 16px;
        }

        &_sanofi-ru {
            margin-top: 4px;
        }

        &_sberbank {
            height: 40px;
            width: 155px;

            margin-top: 4px;
            margin-left: 36px;
        }

        &_unilever {
            width: 46px;
        }

        &_mvideo {
            height: 40px;
            width: 132px;

            margin-top: 2px;
        }

        &_rusal {
            height: 50px;
            width: 106px;

            margin-top: 2px;
        }

        &_alfa {
            height: 30px;
            width: 146px;

            margin-top: 12px;
        }

        &_rosatom {
            width: 140px;
        }

        &_rosgosstrah {
            height: 17px;
            width: 150px;

            margin-top: 16px;
        }

        &_cornerstone {
            height: 30px;
            width: 154px;

            margin-top: 8px;
        }

        &_open {
            height: 30px;
            width: 196px;

            margin-top: 18px;
        }

        &_gaz {
            height: 30px;
            width: 79px;

            margin-top: 21px;
        }

        &_uralhim {
            height: 20px;
            width: 170px;

            margin-top: 21px;
        }

        &_ozon {
            height: 25px;
            width: 114px;

            margin-top: 19px;
        }

        &_samruk {
            height: 60px;
            width: 132px;
        }

        &_gazprom-neft {
            width: 105px;
        }

        &_modulbank {
            width: 180px;
            height: 30px;

            margin-top: 13px;
            margin-left: 28px;
        }

        &_yum {
            width: 60px;

            margin-top: 8px;
        }

        &_e-on {
            height: 30px;
            width: 103px;

            margin-top: 10px;
        }

        &_pfizer {
            height: 40px;
            width: 69px;

            margin-top: 7px;
        }

        &_dtek {
            height: 30px;
            width: 87px;

            margin-top: 9px;
        }

        &_market {
            height: 30px;
            width: 141px;

            margin-top: 12px;
            margin-right: 28px;
        }

        &_gazprom-media {
            height: 30px;
            width: 180px;

            margin-top: 16px;
        }

        &_transmashholding {
            height: 40px;
            width: 173px;

            margin-top: 8px;
            margin-left: 28px;
        }

        &_astrazeneca {
            height: 40px;
            width: 147px;
        }

        &_vtb {
            height: 30px;
            width: 84px;

            margin-top: 21px;
        }

        &_tehnonikol {
            height: 30px;
            width: 178px;

            margin-top: 24px;
            margin-left: 28px;
            margin-right: 28px;
        }

        &_kelly,
        &_kelly-ru {
            height: 30px;
            width: 88px;
        }

        &_kelly {
            margin-top: 9px;
        }

        &_kelly-ru {
            margin-top: 24px;
        }

        &_kaz,
        &_kaz-ru {
            width: 140px;

            margin-left: 28px;
        }

        &_kaz {
            margin-top: -7px;
        }

        &_kaz-ru {
            margin-top: 8px;
        }

        &_mosenergo {
            width: 121px;

            margin-left: 22px;
        }
    }

    &__logotypes-wrapper {
        padding-top: ($baseline * 2) - 28px;

        .clients__logo {
            will-change: opacity;
            opacity: 0;
            transition: opacity 0.5s ease-in;

            &.animation_second-wave {
                transition-delay: 0.5s;
            }

            &.animation_third-wave {
                transition-delay: 1s;
            }
        }

        &.animate .clients__logo {
            opacity: 1;
        }
    }

    &__logotypes-row {
        @include flexbox();
        @include justify-content(center);

        padding-top: 28px;
    }
}
