@import "../base/mixins";
@import "../base/variables";
@import "../base/picture";

@import "../components/announcement";
@import "../components/header";
@import "../components/section";
@import "../components/intro";
@import "../components/clients";
@import "../components/questionnaires";
@import "../components/motivation";
@import "../components/tests";
@import "../components/reliability";
@import "../components/price";
@import "../components/footer";
