.tests {
    overflow: hidden;
    margin-bottom: -100px;

    padding-top: $baseline * 7;

    &__text {
        width: 755px;

        p {
            width: 625px;
        }
    }

    &__iphone-green {
        position: relative;

        width: 271px;
        height: 486px;
        will-change: transform;
        transform: translateX(-30px);
        transition: transform 3s ease-out;

        img {
            width: 100%;
            height: 100%;
        }

        &:after {
            position: absolute;
            top: 64px;
            left: -54px;
            z-index: -1;

            content: '';
            width: 802px;
            height: 429px;

            @include background-image('../../images/tests/iphone-green-shadow.png');
            @include background-image-2x('../../images/tests/iphone-green-shadow@2x.png');
        }
    }

    &__iphone-green-wrapper {
        @include flexbox();
        @include justify-content(center);

        padding-top: 2rem;

        &.animate .tests__iphone-green {
            transform: translateX(0);
        }
    }
}
