.price {
    padding-top: $baseline * 7;

    .wrapper {
        width: 720px;
    }

    &__footer {
        margin-top: $baseline * 2;
        text-align: center;

        a {
            position: relative;

            margin: 0 1rem;
            color: $text-color-main;
            text-decoration: none;

            &:not(.phone):after {
                position: absolute;
                left: 0;
                bottom: -1px;

                content: '';
                width: 100%;
                border-bottom: 1px solid $brand-primary;
            }
        }
    }
}