.footer {
    padding-top: $baseline * 2;

    background-size: 100% 136px;
    background-repeat: repeat-x;
    background-position: bottom;
    background-image: url("../../images/footer-gradient.png");
    @include background-image-2x("../../images/footer-gradient@2x.png");

    &__image {
        display: block;
        width: 452px;
        height: 299px;
    }
}
