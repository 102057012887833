picture {
  font-size: 0;
  display: block;
}

picture img {
  width: 100%;
  height: 100%;
}

.announcement {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  -moz-justify-content: center;
  color: #fff;
  background-color: #46716d;
  justify-content: center;
  margin: 25.2pt 0 32px;
  display: flex;
}

.announcement__text {
  padding: .4rem;
}

.announcement__text a {
  color: #fff;
}

.header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  -moz-align-items: flex-end;
  align-items: flex-end;
  width: 1214px;
  margin: 0 auto;
  display: flex;
}

.header_space-between {
  -moz-justify-content: space-between;
  justify-content: space-between;
}

.header__logo {
  font-size: 0;
}

.header__logo img {
  width: 273px;
  height: 109px;
  margin-left: -10px;
}

.header__links, .header__language-picker {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  margin-bottom: 14px;
  display: flex;
}

.header__links {
  -moz-justify-content: center;
  justify-content: center;
}

.header__links a {
  color: #324c4a;
  font-size: 18pt;
  text-decoration: none;
  display: block;
  position: relative;
}

.header__links a:not(:last-child) {
  margin-right: 1.5rem;
}

.header__links a:not(.phone):after {
  content: "";
  border-bottom: 1px solid #324c4a;
  width: 100%;
  position: absolute;
  bottom: 1px;
  left: 0;
}

.header__links .phone {
  letter-spacing: .01em;
}

.header__links .email {
  margin-left: 50.4pt;
}

.header__language-picker {
  -moz-justify-content: flex-end;
  justify-content: flex-end;
}

.header__language-item {
  font-variant: small-caps;
  letter-spacing: .05em;
  font-size: 18pt;
}

.header__language-item:not(:last-child) {
  position: relative;
}

.header__language-item:not(:last-child):after {
  content: "•";
  color: #46716d;
  margin: 0 .5rem;
}

.header__language-item a {
  color: #46716d;
  text-decoration: none;
}

.header__language-item a:hover {
  color: #000;
}

.header__globe-icon {
  background-image: url("globe.bbf3072b.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 19px;
  height: 19px;
  margin-top: 8px;
  margin-right: .6rem;
  position: relative;
}

section {
  padding-top: 252pt;
}

.intro {
  padding-top: 100.8pt;
}

.intro__text {
  width: 635px;
}

.intro__iphone {
  will-change: transform;
  width: 133px;
  transform: translateY(100px);
}

.intro__iphone_verbal {
  height: 436px;
  transition: transform 2.5s ease-in-out;
}

.intro__iphone_ipsative {
  height: 444px;
  transition: transform 2.25s ease-in-out;
}

.intro__iphone_numerical {
  height: 452px;
  transition: transform 1.75s ease-in-out;
}

.intro__iphones-wrapper {
  position: relative;
}

.intro__iphones-wrapper.animate .intro__iphone {
  transform: translateY(0);
}

.intro__iphones-wrapper .intro__iphone {
  position: absolute;
  left: 50%;
}

.intro__iphones-wrapper .intro__iphone_verbal {
  margin-left: -120px;
  top: 0;
}

.intro__iphones-wrapper .intro__iphone_ipsative {
  margin-left: -67px;
  top: 183px;
}

.intro__iphones-wrapper .intro__iphone_numerical {
  margin-left: 0;
  top: 103px;
}

.intro__ipad {
  width: 750px;
  height: 574px;
  position: relative;
}

.intro__ipad:after {
  z-index: -1;
  content: "";
  background-image: url("ipad-shadow.e1bf48c8.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 880px;
  height: 695px;
  position: absolute;
  top: 0;
  left: 0;
}

@media (-webkit-device-pixel-ratio >= 1.5), (-o-min-device-pixel-ratio: 5 / 2), (min-device-pixel-ratio: 1.5), (resolution >= 144dpi), (resolution >= 1.5dppx) {
  .intro__ipad:after {
    background-image: url("ipad-shadow@2x.a36ea156.png");
  }
}

.intro__ipad_vertical {
  width: 574px;
  height: 750px;
}

.intro__ipad_vertical:after {
  transform-origin: 0 0;
  bottom: 100%;
  transform: rotate(-90deg)scaleX(-1);
}

.intro__ipads-wrapper {
  height: 1425px;
  margin-bottom: -100px;
  padding-bottom: 100px;
  position: relative;
  overflow: hidden;
}

.intro__ipads-wrapper .row {
  -moz-flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: none;
  -moz-justify-content: center;
  flex-wrap: nowrap;
  justify-content: center;
}

.intro__ipads-wrapper .row__column {
  -moz-flex: 1 1 0;
  flex: 1 1 0;
  min-width: 750px;
  margin: 25.2pt;
}

.intro__ipads-wrapper .row__column:first-child {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  -moz-justify-content: flex-end;
  justify-content: flex-end;
  display: flex;
}

.intro__ipads {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.clients__logo {
  height: 50px;
  margin: 0 1rem;
}

.clients__logo_detech {
  width: 180px;
  height: 54px;
  margin: 100.8pt 0 0;
}

.clients__logo_aeroflot {
  width: 182px;
}

.clients__logo_sanofi, .clients__logo_sanofi-ru {
  width: 140px;
  height: 30px;
}

.clients__logo_sanofi {
  margin-top: 16px;
}

.clients__logo_sanofi-ru {
  margin-top: 4px;
}

.clients__logo_sberbank {
  width: 155px;
  height: 40px;
  margin-top: 4px;
  margin-left: 36px;
}

.clients__logo_unilever {
  width: 46px;
}

.clients__logo_mvideo {
  width: 132px;
  height: 40px;
  margin-top: 2px;
}

.clients__logo_rusal {
  width: 106px;
  height: 50px;
  margin-top: 2px;
}

.clients__logo_alfa {
  width: 146px;
  height: 30px;
  margin-top: 12px;
}

.clients__logo_rosatom {
  width: 140px;
}

.clients__logo_rosgosstrah {
  width: 150px;
  height: 17px;
  margin-top: 16px;
}

.clients__logo_cornerstone {
  width: 154px;
  height: 30px;
  margin-top: 8px;
}

.clients__logo_open {
  width: 196px;
  height: 30px;
  margin-top: 18px;
}

.clients__logo_gaz {
  width: 79px;
  height: 30px;
  margin-top: 21px;
}

.clients__logo_uralhim {
  width: 170px;
  height: 20px;
  margin-top: 21px;
}

.clients__logo_ozon {
  width: 114px;
  height: 25px;
  margin-top: 19px;
}

.clients__logo_samruk {
  width: 132px;
  height: 60px;
}

.clients__logo_gazprom-neft {
  width: 105px;
}

.clients__logo_modulbank {
  width: 180px;
  height: 30px;
  margin-top: 13px;
  margin-left: 28px;
}

.clients__logo_yum {
  width: 60px;
  margin-top: 8px;
}

.clients__logo_e-on {
  width: 103px;
  height: 30px;
  margin-top: 10px;
}

.clients__logo_pfizer {
  width: 69px;
  height: 40px;
  margin-top: 7px;
}

.clients__logo_dtek {
  width: 87px;
  height: 30px;
  margin-top: 9px;
}

.clients__logo_market {
  width: 141px;
  height: 30px;
  margin-top: 12px;
  margin-right: 28px;
}

.clients__logo_gazprom-media {
  width: 180px;
  height: 30px;
  margin-top: 16px;
}

.clients__logo_transmashholding {
  width: 173px;
  height: 40px;
  margin-top: 8px;
  margin-left: 28px;
}

.clients__logo_astrazeneca {
  width: 147px;
  height: 40px;
}

.clients__logo_vtb {
  width: 84px;
  height: 30px;
  margin-top: 21px;
}

.clients__logo_tehnonikol {
  width: 178px;
  height: 30px;
  margin-top: 24px;
  margin-left: 28px;
  margin-right: 28px;
}

.clients__logo_kelly, .clients__logo_kelly-ru {
  width: 88px;
  height: 30px;
}

.clients__logo_kelly {
  margin-top: 9px;
}

.clients__logo_kelly-ru {
  margin-top: 24px;
}

.clients__logo_kaz, .clients__logo_kaz-ru {
  width: 140px;
  margin-left: 28px;
}

.clients__logo_kaz {
  margin-top: -7px;
}

.clients__logo_kaz-ru {
  margin-top: 8px;
}

.clients__logo_mosenergo {
  width: 121px;
  margin-left: 22px;
}

.clients__logotypes-wrapper {
  padding-top: 29.4pt;
}

.clients__logotypes-wrapper .clients__logo {
  will-change: opacity;
  opacity: 0;
  transition: opacity .5s ease-in;
}

.clients__logotypes-wrapper .clients__logo.animation_second-wave {
  transition-delay: .5s;
}

.clients__logotypes-wrapper .clients__logo.animation_third-wave {
  transition-delay: 1s;
}

.clients__logotypes-wrapper.animate .clients__logo {
  opacity: 1;
}

.clients__logotypes-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  -moz-justify-content: center;
  justify-content: center;
  padding-top: 28px;
  display: flex;
}

.questionnaires {
  overflow: hidden;
}

.questionnaires__text p {
  width: 680px;
}

.questionnaires__surface-studio-wrapper {
  position: relative;
}

.questionnaires__surface-studio-wrapper.animate .questionnaires__surface-studio {
  transform: translate(0);
}

.questionnaires__surface-studio {
  will-change: transform;
  width: 640px;
  height: 641px;
  transition: transform 5s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(30px, 26px);
}

.questionnaires__surface-book {
  width: 841px;
  height: 546px;
  display: block;
  position: absolute;
  left: 0;
}

.questionnaires__surface-book:after {
  z-index: -1;
  content: "";
  background-image: url("surface-book-shadow.59c9fa4b.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 975px;
  height: 644px;
  position: absolute;
  top: -1px;
  left: -22px;
}

@media (-webkit-device-pixel-ratio >= 1.5), (-o-min-device-pixel-ratio: 5 / 2), (min-device-pixel-ratio: 1.5), (resolution >= 144dpi), (resolution >= 1.5dppx) {
  .questionnaires__surface-book:after {
    background-image: url("surface-book-shadow@2x.dca48f85.png");
  }
}

.questionnaires__surface-book_sales {
  z-index: 2;
  width: 827px;
  height: 641px;
}

.questionnaires__surface-book_sales:after {
  z-index: -1;
  background-image: url("surface-book-angled-shadow.a9b655ce.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1175px;
  height: 410px;
  inset: auto 58px -124px auto;
}

@media (-webkit-device-pixel-ratio >= 1.5), (-o-min-device-pixel-ratio: 5 / 2), (min-device-pixel-ratio: 1.5), (resolution >= 144dpi), (resolution >= 1.5dppx) {
  .questionnaires__surface-book_sales:after {
    background-image: url("surface-book-angled-shadow@2x.3118abfa.png");
  }
}

.questionnaires__surface-books-wrapper {
  height: 1017px;
  margin-top: 50.4pt;
  position: relative;
}

.questionnaires__surface-books-wrapper .questionnaires__surface-book {
  position: absolute;
  top: 0;
  left: -70px;
}

.questionnaires__surface-books-wrapper .questionnaires__surface-book_competencies {
  top: 472px;
}

.questionnaires__surface-books-wrapper .questionnaires__surface-book_sales {
  top: 50px;
  left: auto;
  right: -110px;
}

.motivation {
  padding-top: 176.4pt;
  overflow: hidden;
}

.motivation__ipad {
  will-change: transform;
}

.motivation__ipad_dd-all {
  width: 419px;
  height: 493px;
  transition: transform 2s ease-out;
  transform: translateY(-20px);
}

.motivation__ipad_dd-recommendations {
  width: 508px;
  height: 599px;
  transition: transform 2s ease-out;
  transform: translateY(20px);
}

.motivation__ipads-section {
  padding-top: 4rem;
}

.motivation__ipads-wrapper {
  height: 653px;
  position: relative;
}

.motivation__ipads-wrapper.animate .motivation__ipad {
  transform: translateY(0);
}

.motivation__ipads-wrapper .motivation__ipad {
  position: absolute;
  top: 0;
  left: 0;
}

.motivation__ipads-wrapper .motivation__ipad_dd-all {
  z-index: 2;
  top: 160px;
  left: 50px;
}

.motivation__ipads-wrapper .motivation__ipad_dd-recommendations {
  z-index: 1;
  top: -80px;
  left: 240px;
}

.tests {
  margin-bottom: -100px;
  padding-top: 176.4pt;
  overflow: hidden;
}

.tests__text {
  width: 755px;
}

.tests__text p {
  width: 625px;
}

.tests__iphone-green {
  will-change: transform;
  width: 271px;
  height: 486px;
  transition: transform 3s ease-out;
  position: relative;
  transform: translateX(-30px);
}

.tests__iphone-green img {
  width: 100%;
  height: 100%;
}

.tests__iphone-green:after {
  z-index: -1;
  content: "";
  background-image: url("iphone-green-shadow.095df64a.png");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 802px;
  height: 429px;
  position: absolute;
  top: 64px;
  left: -54px;
}

@media (-webkit-device-pixel-ratio >= 1.5), (-o-min-device-pixel-ratio: 5 / 2), (min-device-pixel-ratio: 1.5), (resolution >= 144dpi), (resolution >= 1.5dppx) {
  .tests__iphone-green:after {
    background-image: url("iphone-green-shadow@2x.b95a8fd9.png");
  }
}

.tests__iphone-green-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  -moz-justify-content: center;
  justify-content: center;
  padding-top: 2rem;
  display: flex;
}

.tests__iphone-green-wrapper.animate .tests__iphone-green {
  transform: translateX(0);
}

.reliability {
  padding-top: 201.6pt;
}

.reliability__section:not(:first-child) {
  padding-top: 75.6pt;
}

.reliability__old-display, .reliability__emblem {
  width: 322px;
}

.reliability__emblem {
  height: 357px;
}

.reliability__old-display {
  height: 336px;
}

.reliability__image-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  -moz-justify-content: center;
  justify-content: center;
  padding-top: 75.6pt;
  display: flex;
}

.price {
  padding-top: 176.4pt;
}

.price .wrapper {
  width: 720px;
}

.price__footer {
  text-align: center;
  margin-top: 50.4pt;
}

.price__footer a {
  color: #000;
  margin: 0 1rem;
  text-decoration: none;
  position: relative;
}

.price__footer a:not(.phone):after {
  content: "";
  border-bottom: 1px solid #46716d;
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.footer {
  background-image: url("footer-gradient.8d85d214.png");
  background-position: bottom;
  background-repeat: repeat-x;
  background-size: 100% 136px;
  padding-top: 50.4pt;
}

@media (-webkit-device-pixel-ratio >= 1.5), (-o-min-device-pixel-ratio: 5 / 2), (min-device-pixel-ratio: 1.5), (resolution >= 144dpi), (resolution >= 1.5dppx) {
  .footer {
    background-image: url("footer-gradient@2x.11e040fd.png");
  }
}

.footer__image {
  width: 452px;
  height: 299px;
  display: block;
}

/*# sourceMappingURL=index.8a1d15d9.css.map */
