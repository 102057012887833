.header {
    @include flexbox();
    @include align-items(flex-end);

    width: $grid-width;
    margin: 0 auto;

    &_space-between {
        @include justify-content(space-between);
    }

    &__logo {
        font-size: 0;

        img {
            margin-left: -10px;
            width: 273px;
            height: 109px;
        }
    }

    &__links,
    &__language-picker {
        @include flexbox();
        margin-bottom: 14px;
    }

    &__links {
        @include justify-content(center);

        a {
            display: block;
            position: relative;

            color: $brand-primary-text;
            font-size: 18pt;
            text-decoration: none;

            &:not(:last-child) {
                margin-right: 1.5rem;
            }

            &:not(.phone):after {
                position: absolute;
                left: 0;
                bottom: 1px;

                content: '';
                width: 100%;
                border-bottom: 1px solid $brand-primary-text;
            }
        }

        .phone {
            letter-spacing: 0.01em;
        }

        .email {
            margin-left: 50.4pt;
        }
    }

    &__language-picker {
        @include justify-content(flex-end);
    }

    &__language-item {
        font-size: 18pt;
        font-variant: small-caps;
        letter-spacing: 0.05em;

        &:not(:last-child) {
            position: relative;

            &:after {
                margin: 0 0.5rem;
                content: "•";
                color: $brand-primary;
            }
        }

        a {
            color: $brand-primary;
            text-decoration: none;

            &:hover {
                color: $text-color-main;
            }
        }
    }

    &__globe-icon {
        position: relative;

        width: 19px;
        height: 19px;
        margin-top: 8px;
        margin-right: 0.6rem;

        @include background-image("../../images/globe.svg");
    }
}
