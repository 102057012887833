.reliability {
    padding-top: $baseline * 8;

    &__section:not(:first-child) {
        padding-top: $baseline * 3;
    }

    &__old-display,
    &__emblem {
        width: 322px;
    }

    &__emblem {
        height: 357px;
    }

    &__old-display {
        height: 336px;
    }

    &__image-wrapper {
        @include flexbox();
        @include justify-content(center);

        padding-top: $baseline * 3;
    }

}
