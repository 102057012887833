@import "../../../node_modules/sass-flex-mixin/flex";

@mixin fontface($name, $file, $weight: normal, $style: normal) {
    @font-face {
        font-display: swap;
        font-family: '#{$name}';
        font-style: $style;
        font-weight: $weight;
        src: url('../fonts/#{$file}.woff') format('woff');
    }
}

@mixin background-image-2x($file) {
    @media (-webkit-min-device-pixel-ratio: 1.5),
            (-o-min-device-pixel-ratio: 5/2),
            (min-device-pixel-ratio: 1.5),
            (min-resolution: 144dpi),
            (min-resolution: 1.5dppx) {
        background-image: url($file);
    }
}

@mixin background-image($file) {
    background-image: url($file);
    background-size: 100% auto;
    background-repeat: no-repeat;
}
